<template>
  <TModal
    :title="!is_edit ? 'Create' : 'Edit'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <TInputText
      label="Name"
      class="mb-3"
      :value="input.name"
      @update:value="input.name = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    name: "",
  },
  data() {
    return {
      key: "customsUnitModal",
      loading: false,
      data: this.getInput(),
    };
  },
  watch: {
    unit() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.unit) {
        return false;
      }
      return !!this.unit.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.freight_forwarders.create", this.input)
        .then(() => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("warehouse.freight_forwarders.update", {
          id: this.unit.id,
          attributes: this.input,
        })
        .then(() => {
          this.$store.dispatch("warehouse.freight_forwarders.fetch");
          this.key = this.lodash.resetKey("customsModal");
          this.$emit("update:show", false);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return this.lodash.pick(this.unit, ["name"]);
    },
  },
};
</script>
